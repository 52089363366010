<template>
  <div class="sovCateWrap">
    <sovTitle cardTitle="时间范围" type="category" :brand="category"></sovTitle>
    <sovGeneralView
      title="品类声量概览"
      :productId="id"
      :timeRange="timeRange"
      :viewLoading="viewLoading"
      :tableData="generalViewTableData"
    ></sovGeneralView>
    <sovDistribute
      title="品类声量分布"
      headerWordType="3"
      :isHasTotal="false"
    ></sovDistribute>
    <sovChartCart
      title="品类声量趋势"
      @seeBrandChart="seeBrandChart"
      @seeTotalChart="seeTotalChart"
      @timeSel="timeSel"
      @timeSelCate="timeSelCate"
      @cateFilter="cateFilter"
      :tempCampaignFilter="tempCampaignFilter"
      :campaignList="cateList"
      :echartLegend="echartLegend"
      :chartData="chartData"
    >
    </sovChartCart>
  </div>
</template>

<script>
import sovTitle from "../sovCommonComponents/sovTitle";
import sovGeneralView from "./categoryComponents/sovGeneralView";
import sovDistribute from "../sovCommonComponents/sovDistribute";
import sovChartCart from "./categoryComponents/sovChartCart";
import {
  getCategoryOverview,
  getCategoryTrend,
  getCategoryComparisonTrend,
  getCategoryMonth,
} from "@/api/sov/sovCategory";
import {
  setStartTime,
  setEndTime,
  setStartWeekTime,
  setEndWeekTime,
  setStartMonthTime,
  setEndMonthTime,
} from "../sovCommonFunc/index";

export default {
  data() {
    return {
      timeRange: "1mon",
      viewLoading: false,
      generalViewTableData: [],
      echartsTime: "day",
      yType: "exposure",
      trendType: "cpi",
      originLegend: [],
      originSeries: [],
      chartData: {
        echartxAxis: {
          data: [
            201905, 201906, 201907, 201908, 201909, 201910, 201911, 201912,
            202001, 202002,
          ],
          type: "category",
        },
        echartSeries: [
          {
            data: [
              73079874, 73584250, 55022549, 62489669, 54538919, 42380599,
              28357890, 42266074, 53824757, 38509705,
            ],
            name: "",
            type: "line",
            smooth: true,
          },
        ],
        echartColor: ["#7CA526", "#5E9AF4", "#5B4A99"],
      },
      defaultColors: [
        "#c23531",
        "#2f4554",
        "#61a0a8",
        "#d48265",
        "#91c7ae",
        "#749f83",
        "#ca8622",
        "#bda29a",
        "#6e7074",
        "#546570",
        "#c4ccd3",
        "#cf9856",
        "#d3baed",
        "#c4efed",
        "#efd666",
        "#bc736a",
        "#bb909e",
        "#bcd094",
        "#f2f0bf",
        "#c58a89",
      ],
      originData: [],
      cateList: [],
      echartLegend: [],
      tempCateFilter: [],
      tempCampaignFilter: [],
      timeMonth: "",
      timeYear: "",
    };
  },
  props: {
    category: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    duration: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.timeRange = this.duration;
    this.init();
  },
  methods: {
    init() {
      this.getCategoryMonth();
      this.getCategoryOverview();
      this.getCategoryTrend();
    },
    async getCategoryMonth() {
      let res = await getCategoryMonth();
      console.log(res);
      if (res.code === 0) {
        this.timeMonth = res.data.split("-")[1];
        this.timeYear = res.data.split("-")[0];
        console.log(this.timeMonth, this.timeYear);
      } else {
        this.$message.error("获取时间失败，请联系管理员!");
      }
    },
    async getCategoryOverview() {
      this.viewLoading = true;
      let res = await getCategoryOverview({
        duration: this.timeRange,
        id: this.id,
      });
      if (res.code === 0) {
        this.formatNull(res.data);
        this.generalViewTableData = res.data;
        this.viewLoading = false;
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },
    async getCategoryTrend() {
      let res = await getCategoryTrend({
        duration: this.timeRange,
        id: this.id,
        xType: this.echartsTime,
        yType: this.yType,
      });
      if (res.code === 0) {
        this.cateList = this.getCateList(res.data);
        this.originData = [].concat(res.data);
        this.initTrendData(res.data);
        this.originLegend = this.echartLegend;
        this.originSeries = this.chartData.echartSeries;
        if (this.tempCateFilter.length === 0) {
          this.cateFilter(this.cateList);
        } else {
          this.cateFilter(this.tempCateFilter);
        }
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },
    async getCategoryComparisonTrend() {
      let res = await getCategoryComparisonTrend({
        duration: this.timeRange,
        id: this.id,
        xType: this.echartsTime,
        yType: this.yType,
      });
      if (res.code === 0) {
        this.cateList = this.getCateList(res.data);
        this.originData = [].concat(res.data);
        this.initTrendData(res.data);
        this.originLegend = this.echartLegend;
        this.originSeries = this.chartData.echartSeries;
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },
    initTrendData(data) {
      console.log(this.trendType);
      if (this.trendType === "cpi") {
        this.tempType = "trend";
      } else {
        this.tempType = "exposureTrend";
      }
      this.echartLegend = [];
      const startTimeArr = [];
      const endTimeArr = [];
      console.log(data);
      data.forEach((campaign, i) => {
        this.echartLegend.push({
          name: campaign.name,
          color: this.chartData.echartColor.concat(this.defaultColors)[i],
          index: Math.random() * 2 * Math.random(),
        });
        console.log(campaign, campaign[this.tempType], this.tempType);
        startTimeArr.push(campaign[this.tempType][0].xAxis);
        const lastIndex = campaign[this.tempType].length - 1;
        endTimeArr.push(campaign[this.tempType][lastIndex].xAxis);
      });
      if (this.echartsTime === "day") {
        startTimeArr.push(
          setStartTime(this.timeRange, this.timeMonth, this.timeYear)
        );
        endTimeArr.push(
          setEndTime(this.timeRange, this.timeMonth, this.timeYear)
        );
        // day
        this.formatDayDate(startTimeArr, endTimeArr);
      } else if (this.echartsTime === "week") {
        // week
        startTimeArr.push(
          setStartWeekTime(this.timeRange, this.timeMonth, this.timeYear)
        );
        endTimeArr.push(
          setEndWeekTime(this.timeRange, this.timeMonth, this.timeYear)
        );
        this.formatWeekDate(startTimeArr, endTimeArr);
      } else {
        // month
        startTimeArr.push(
          setStartMonthTime(this.timeRange, this.timeMonth, this.timeYear)
        );
        endTimeArr.push(
          setEndMonthTime(this.timeRange, this.timeMonth, this.timeYear)
        );
        this.formatMonthDate(startTimeArr, endTimeArr);
      }
    },
    formatDayDate(startTimeArr, endTimeArr, type) {
      const startTimeStamp = startTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      const endTimeStamp = endTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      this.getDayAllDate(startTime, endTime, type);
    },
    getDayAllDate(start, end, type) {
      console.log(start, end);
      let dateArr = [];
      let startArr = start.split("-");
      let endArr = end.split("-");
      console.log(startArr, endArr);
      console.log(startArr[0], startArr[1] - 1, startArr[2]);
      let db = new Date();
      db.setFullYear(startArr[0], startArr[1] - 1, startArr[2]);
      let de = new Date();
      de.setFullYear(endArr[0], endArr[1] - 1, endArr[2]);
      console.log(db, de);
      let stampDb = db.getTime();
      let stampDe = de.getTime();
      console.log(stampDb, stampDe);
      let stamp = stampDb;
      const oneDay = 24 * 60 * 60 * 1000;
      for (stamp; stamp <= stampDe; stamp += oneDay) {
        console.log(stamp);
        dateArr.push(this.timeStampToDate(stamp));
      }
      this.chartData.echartxAxis.data = dateArr;
      console.log(dateArr);
      if (type === "product") {
        this.chartDataProduct.echartxAxis.data = dateArr;
      }
      if (type === "cate") {
        this.getCateDataFromOrigin();
      } else if (type === "product") {
        this.getProductDataFromOrigin();
      } else {
        this.getDataFromOrigin();
      }
    },
    formatWeekDate(startTimeArr, endTimeArr, type) {
      const nStartTimeArr = startTimeArr.map((time) => time.slice(0, 10));
      const nEndTimeArr = endTimeArr.map((time) => time.slice(-10));
      console.log(nStartTimeArr, nEndTimeArr);
      const startTimeStamp = nStartTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      console.log(startTime);
      const endTimeStamp = nEndTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      console.log(endTime);
      this.getWeekAllDate(startTime, endTime, type);
    },
    getWeekAllDate(start, end, type) {
      console.log(start, end);
      let startArr = start.split("-");
      let endArr = end.split("-");
      let db = new Date();
      db.setFullYear(startArr[0], startArr[1] - 1, startArr[2]);
      let de = new Date();
      de.setFullYear(endArr[0], endArr[1] - 1, endArr[2]);
      let stampDb = db.getTime();
      let stampDe = de.getTime();
      let stamp = stampDb;
      const oneWeek = 7 * 24 * 60 * 60 * 1000;
      let stampArr = [];
      for (stamp; stamp <= stampDe; stamp += oneWeek) {
        stampArr.push(stamp);
      }
      console.log(stampArr);
      const sixDay = 6 * 24 * 60 * 60 * 1000;
      let dateArr = [];
      dateArr = stampArr.map((stamp) => {
        const weekLastDayStamp = stamp + sixDay;
        return (
          this.timeStampToDate(stamp) +
          " ~ " +
          this.timeStampToDate(weekLastDayStamp)
        );
      });
      console.log(dateArr);
      this.chartData.echartxAxis.data = dateArr;
      if (type === "product") {
        this.chartDataProduct.echartxAxis.data = dateArr;
      }
      // if (type) {
      //   this.getCateDataFromOrigin()
      // } else {
      //   this.getDataFromOrigin()
      // }
      console.log(type);
      if (type === "cate") {
        this.getCateDataFromOrigin();
      } else if (type === "product") {
        this.getProductDataFromOrigin();
      } else {
        this.getDataFromOrigin();
      }
      // this.getDataFromOrigin()
    },
    formatMonthDate(startTimeArr, endTimeArr, type) {
      const startTimeStamp = startTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      console.log(startTime);
      const endTimeStamp = endTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      console.log(endTime);
      this.getMonthAllDate(startTime, endTime, type);
    },
    getMonthAllDate(start, end, type) {
      let startArr = start.split("-");
      let startYear = startArr[0] * 1;
      let startMonth = startArr[1] * 1;
      let endArr = end.split("-");
      let endYear = endArr[0] * 1;
      let endMonth = endArr[1] * 1;
      let dateArr = [];
      if (startYear < endYear) {
        for (startMonth; startMonth <= 12; startMonth++) {
          const tempStr =
            startYear + "-" + (startMonth < 10 ? "0" + startMonth : startMonth);
          dateArr.push(tempStr);
          if (startMonth === 12 && startYear + 1 < endYear) {
            startYear++;
            startMonth = 0;
          }
        }
        for (let i = 1; i <= endMonth; i++) {
          const tempStr = endYear + "-" + (i < 10 ? "0" + i : i);
          dateArr.push(tempStr);
        }
      } else {
        for (startMonth; startMonth <= endMonth; startMonth++) {
          const tempStr =
            endYear + "-" + (startMonth < 10 ? "0" + startMonth : startMonth);
          dateArr.push(tempStr);
        }
      }
      console.log(dateArr);
      this.chartData.echartxAxis.data = dateArr;
      if (type === "product") {
        this.chartDataProduct.echartxAxis.data = dateArr;
      }
      if (type === "cate") {
        this.getCateDataFromOrigin();
      } else if (type === "product") {
        this.getProductDataFromOrigin();
      } else {
        this.getDataFromOrigin();
      }
    },
    timeStampToDate(timestamp) {
      // 1612197881157
      // 1612198020199
      console.log(timestamp);
      const date = new Date(timestamp);
      const Y = date.getFullYear();
      const M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      console.log(Y, M, D);
      return Y + "-" + M + "-" + D;
    },
    getDataFromOrigin() {
      console.log(this.originData);
      this.chartData.echartSeries = this.originData.map((campaign) => {
        const seriesItem = {
          data: [],
          name: "",
          type: "line",
          smooth: true,
        };
        seriesItem.name = campaign.name;
        // 重写逻辑：从整个时间轴上截取时间段，段内补0，段外补null
        const startTimeIndex = this.chartData.echartxAxis.data.indexOf(
          campaign[this.tempType][0].xAxis
        );
        const endTimeIndex = this.chartData.echartxAxis.data.indexOf(
          campaign[this.tempType][campaign[this.tempType].length - 1].xAxis
        );
        console.log(startTimeIndex, endTimeIndex);
        const campaignxAxis = this.chartData.echartxAxis.data.slice(
          startTimeIndex,
          endTimeIndex + 1
        );
        console.log(campaignxAxis);
        seriesItem.data = this.chartData.echartxAxis.data.map((xAxis) => {
          let data = 0;
          if (campaignxAxis.includes(xAxis)) {
            const trend = campaign[this.tempType].find(
              (item) => item.xAxis === xAxis
            );
            trend ? (data = trend.yAxis) : (data = 0);
          }
          return data;
        });
        return seriesItem;
      });
    },
    timeSel(val) {
      this.echartsTime = val;
      this.getCategoryTrend();
    },
    seeTotalChart() {
      this.cateList = [this.category];
      this.tempCampaignFilter = this.cateList;
      this.getCategoryComparisonTrend();
    },
    seeBrandChart() {
      this.tempCateFilter = [];
      this.getCategoryTrend();
    },
    getCateList(data) {
      let tempArr = [];
      console.log(data);
      data.forEach((v) => {
        tempArr.push(v.name);
      });
      return tempArr;
    },
    cateFilter(cateFilter) {
      this.tempCateFilter = cateFilter;
      this.tempCampaignFilter = cateFilter;
      const cateSeriesTemp = [];
      const cateFilterTemp = [];
      this.originLegend.forEach((k) => {
        cateFilter.forEach((v) => {
          if (k.name === v) {
            cateFilterTemp.push(k);
          }
        });
      });
      cateFilterTemp.forEach((v, i) => {
        v.color = this.chartData.echartColor.concat(this.defaultColors)[i];
      });
      this.echartLegend = cateFilterTemp;
      this.originSeries.forEach((series) => {
        cateFilter.forEach((v) => {
          if (series.name === v) {
            cateSeriesTemp.push(series);
          }
        });
      });
      console.log(cateSeriesTemp);
      this.chartData.echartSeries = cateSeriesTemp;
    },
    productFilter(productFilter) {
      const productTemp = [];
      const productSeries = [];
      this.productOriginLegend.forEach((k) => {
        productFilter.forEach((v) => {
          if (k.name === v) {
            productTemp.push(k);
          }
        });
      });
      // 重新设置颜色
      productTemp.forEach((v, i) => {
        v.color = this.chartDataProduct.echartColor.concat(this.defaultColors)[
          i
        ];
      });
      this.productLegend = productTemp;
      this.productOriginSeries.forEach((series) => {
        productFilter.forEach((v) => {
          if (series.name === v) {
            productSeries.push(series);
          }
        });
      });
      this.chartDataProduct.echartSeries = productSeries;
    },
    timeSelCate(val) {
      this.echartsTime = val;
      this.getCategoryComparisonTrend();
    },
  },
  components: {
    sovTitle,
    sovGeneralView,
    sovDistribute,
    sovChartCart,
  },
};
</script>

<style lang="scss" scoped>
.sovCateWrap {
  width: 100%;
  height: 100%;
}
</style>
